<template>
  <i v-bind:class="getClass" v-tooltip.left="getTitle"></i>
</template>

<script>
export default {
  name: "TableList",
  props: {
    className: {
      default: "",
    },
    dataTitle: {
      default: "",
    },
  },
  computed: {
    getTitle() {
      let result;
      if (this.dataTitle) {
        result = this.dataTitle;
      } else {
        result = "Kötü";
      }

      return result;
    },
    getClass() {
      if (this.className) {
        return;
      }

      return "fas fa-rocket fa-lg seo-bad text-danger";
    },
  },
};
</script>

<style lang="scss" scoped>
.seo-success {
  transform: rotate(0);
  vertical-align: text-bottom;
  color: #2ed573;
}

.seo-mid {
  transform: rotate(45deg);
  vertical-align: text-top;
}

.seo-bad {
  transform: rotate(90deg);
  vertical-align: top;
}
</style>
